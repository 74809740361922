import { OrderFormHTMLElement } from "./order-form-html-element";
import { OrderFormElementInterface } from "./order-form-element.interface";

export const _DS24_EVENT_ORIGIN: string = document.currentScript
    ? new URL((document.currentScript as HTMLScriptElement).src).origin
    : "https://www.digistore24.com";

export function getWidgetOrderFormList(): OrderFormElementInterface[] {
    const result: (OrderFormElementInterface)[] = [];

    const pay_iframes = document.querySelectorAll("my-order-form");

    for (let i = 0; i < pay_iframes.length; i++) {
        if(pay_iframes.item(i) instanceof OrderFormHTMLElement) {
            result.push(pay_iframes.item(i) as OrderFormHTMLElement);
        }
    }
  
    return result;
}
