import { _DS24_EVENT_ORIGIN, getWidgetOrderFormList } from "./get-order-form-list";
import { OrderFormElementInterface } from "./order-form-element.interface";
import { OrderFormIframeWrapper } from "./order-form-iframe-wrapper";
import { getWidgetTrackingParameter } from "./tracking";

export class OrderFormHTMLElement extends HTMLElement implements OrderFormElementInterface {

    static observedAttributes = ["width"];

    private _root?: ShadowRoot;
    private _index = 0;
    private _wrapper?: OrderFormIframeWrapper;

    get activeStep() {
        return this._wrapper?.activeStep;
    }

    get totalStepCount() {
        return this._wrapper?.totalStepCount;
    }

    constructor(private _iframe?: HTMLIFrameElement) {
        super();
    }

    public static createFromLegacyIframe(payFrame: HTMLIFrameElement) {
        const element = new OrderFormHTMLElement(payFrame);
        const payFrameUrl = new URL(payFrame.src);
        const hasProductInPath = payFrameUrl.pathname.startsWith('/product/');
        if (hasProductInPath) {
            const [productId, orderformId] = payFrameUrl.pathname.substring(9).split('/');
            element.setAttribute('product-id', productId);
            element.setAttribute('order-form-id', orderformId);
        }
        element.setAttribute('base-url', payFrameUrl.origin);
        if (payFrameUrl.search) {
            element.setAttribute('query', payFrameUrl.search.substring(1));
        }
        
        return element;
    }

    public connectedCallback() {
        this._root = this.attachShadow({ mode: "open" });

        if (!this._iframe) {
            this._iframe = document.createElement('iframe');
            this._iframe.style.setProperty('overflow', 'hidden');
            this._iframe.style.setProperty('width', this.getAttribute('width') ?? '100%');
            this._iframe.style.setProperty('height', '600px');
            this._iframe.style.setProperty('border', 'none');
            this._iframe.style.setProperty('background', 'transparent');
            this._iframe.setAttribute('sandbox', 'allow-top-navigation allow-modals allow-scripts allow-forms allow-popups allow-presentation allow-popups-to-escape-sandbox allow-same-origin');
            this._iframe.setAttribute('loading', 'lazy');
        }

        const trackingParameter = getWidgetTrackingParameter();

        this._index = this._getIndex();
        /**
         * die ds24_cart_url wird nur beim ersten Orderform gesetzt!
         */
        if (trackingParameter.ds24_cart_url && this._index === 0) {
            this._iframe.setAttribute('src', trackingParameter.ds24_cart_url);
        } else {
            this._iframe.setAttribute('src', this._buildOrderFormUrl());
        }

        this._root.appendChild(this._iframe);

        this._wrapper = new OrderFormIframeWrapper(this._iframe, this._index, this);
    }

    public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        this._iframe?.style.setProperty(name, newValue);
    }

    private _buildOrderFormUrl() {
        const baseUrl = this.getAttribute('base-url') ?? _DS24_EVENT_ORIGIN;
        const urlParts = ['/product', this.getAttribute('product-id')];
        const orderformId = this.getAttribute('order-form-id');
        if (orderformId) {
            urlParts.push(orderformId);
        }
        const queryParamList = ['is_in_iframe=1'];
        const ds24ref = document.referrer;
        if (ds24ref) {
            queryParamList.push('ds24ref=' + encodeURI(ds24ref));
        }
        if (this.getAttribute('query')) {
            queryParamList.push((this.getAttribute('query') as string).replace('&amp;', '&'));
        }
        return baseUrl + urlParts.join('/') + '?' + queryParamList.join('&');
    }

    private _getIndex() {
        const formList = getWidgetOrderFormList();
        return formList.indexOf(this);
    }

    public callApi(action: string, payload: any): void {
        this._wrapper?.callApi(action, payload);
    }


}

