import { getWidgetOrderFormList } from "./get-order-form-list";
import { OrderFormElementInterface } from "./order-form-element.interface";

async function _widget_api_proxy(callback: (element: OrderFormElementInterface) => void, msob_index?: number, tries = 0) {
  await customElements.whenDefined('my-order-form');
  const widgetList = getWidgetOrderFormList();
  if (tries > 6) {
    console.log("DS24: Order form widget api call timeout!");
    return;
  }
  if (typeof(msob_index) === 'undefined' || msob_index < 0) {
    if(widgetList.length === 0) {
      setTimeout(() => {
        _widget_api_proxy(callback, msob_index, tries + 1);
      }, 450 + tries * 25);
      return;
    }
    widgetList.forEach((element: any) => {
      callback(element);
    });
  } else {
    if (!widgetList[msob_index]) {
      setTimeout(() => {
        _widget_api_proxy(callback, msob_index, tries + 1);
      }, 450 + tries * 25);
      return;
    }
    callback(widgetList[msob_index]);
  }
}

/*
 * Digistore24 Orderform Widget Api
 */

/**
 * Sets the editor mode for the orderform widget.
 *
 * Alternative: In iframe url use GET-Parameter editor_mode=1
 *
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 */

export function ds24_setEditorMode(msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi("setEditorMode", {});
  }, msob_index);
}

/**
 * Displays some error messages, so that web designers can easier modify the css code.
 *
 * Alternative: In iframe url use GET-Parameter show_dummy_error_messages=1
 *
 * @param: {bool} are_messages_shown
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 */
export function ds24_showDummyErrorMessages(are_messages_shown: boolean, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi("showDummyErrorMessage",
      { are_messages_shown: are_messages_shown }
    );
  }, msob_index);
}
  
/**
 * Reloads the iframe. Use this in your editor after changes on the orderform settings.
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 *
 */
export function ds24_reloadIframe(msob_index?: number) {
  _widget_api_proxy((widget) => {
      widget.callApi("reload",
        { }
      );
    }, msob_index);
}
  
  /**
   * Sets the current step
   *
   * @param: {int} step_no
   * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
   *
   */
export function ds24_setStep(step_no: number, msob_index?: number) {
  _widget_api_proxy((widget) => {
      widget.callApi("setStepNo", { step_no: step_no });
    }, msob_index);
}

  
/**
 * Adds an event listener for Digistor24 events
 *
 * @param: {string} event_name
 * @param: {function} event_handler
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 *
 * event_handler is declared as: function( event_name, event_details ).
 *
 * Supported events:
 * stepChange: event_details = { newStep: NUMBER }
 */
export function ds24_addEventListener(event_name: string, event_handler: (event_name: string, payload: any) => void, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.addEventListener(
      "ds24_" + event_name,
      function (e: Event) {
        event_handler(event_name, (e as CustomEvent).detail);
      },
      false
    );
  }, msob_index);
}

/**
 * Sets the current contact data
 *
 * @param: {object} address with keys: first_name, last_name and so on
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 *
 */
export function ds24_setContactData(address: any, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi("setContactData", address);
  }, msob_index);
}

/**
 * Selects a payment plan
 *
 * @param: {int} plan_index_or_id
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 *
 */
export function ds24_setPaymentPlan(plan_index_or_id: string, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi("setPaymentPlan", { plan_index_or_id: plan_index_or_id });
  }, msob_index);
}

/**
 * Selects a quantity for a product
 *
 * @param: {int} product_index_or_id
 * @param: {int} quantity - defaults to 1
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 */
export function ds24_setQuantity(product_index_or_id: string, quantity: number, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi(
      "setQuantity",
      { product_index_or_id: product_index_or_id, quantity: quantity },
    );
  }, msob_index);
}

/**
 * Selects the currency
 *
 * @param: {string} currency, e.g. EUR or USD
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 *
 */
export function ds24_setCurrency(currency: string, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi(
      "setCurrency", { currency: currency }
    );
  }, msob_index);
}

/**
 * Sets the discount voucher code
 *
 * @param: {string} currency, e.g. EUR or USD
 * @param: {bool} is_result_message_shown. if false,, the voucher is applied silently. If true, a message is shown. Defaults to true.
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 *
 */
export function ds24_setDiscountVoucher(
  voucher_code: string,
  lock_input: boolean,
  show_message: boolean,
  msob_index?: number
) {
  _widget_api_proxy((widget) => {
    widget.callApi(
      "setDiscountVoucher",
    {
      voucher_code: voucher_code,
      lock_input: lock_input,
      show_message: show_message,
    },
    );
  }, msob_index);
}

/**
 * Returns the current step no.
 * @returns {number}
 * @param: {int} msob_index - defaults to first multi step order box, i.e. msob_index=0.
 */
export function ds24_currentStep(msob_index?: number) {
  if (typeof msob_index == "undefined") {
    msob_index = 0;
  }

  const widgetList = getWidgetOrderFormList();
  return widgetList[msob_index]?.activeStep ? widgetList[msob_index].activeStep : 0;
}

/**
 * Returns the current step no.
 * @returns {number}
 * @param: {int} msob_index - defaults to first multi step order box, i.e. msob_index=0.
 */
export function ds24_totalStepCount(msob_index?: number) {
  if (typeof msob_index == "undefined") {
    msob_index = 0;
  }

  const widgetList = getWidgetOrderFormList();
  return widgetList[msob_index]?.totalStepCount ? widgetList[msob_index].totalStepCount : 0;
}

/**
 * Sets the affiliate for the current purchase
 * @param {string} affiliate_name
 * @param {string} campaignkey (optional)
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 */
export function ds24_setAffiliate(affiliate_name: string, campaignkey: string, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi(
      "setAffiliate", {
        affiliate: affiliate_name,
        campaignkey: campaignkey,
      },
    );
  }, msob_index);
}

/**
 * Sets the tracking key for the current purchase
 * @param {string} tracking_key
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 */
export function ds24_setTrackingKey(tracking_key: string, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi(
      "setTrackingkey", { tracking_key: tracking_key }
    );
  }, msob_index);
}

/**
 * Sets the custom parameter the current purchase. It is transferrec as parameter "custom" in the ipn notification.
 * @param {string} custom. Max. 63 characters. Allowed are letters, digits and  . , ~ - _ spaces
 * @param: {int} msob_index - defaults to all multi step order boxes. 0 = first multi step order box.
 */
export function ds24_setCustomParam(custom: string, msob_index?: number) {
  _widget_api_proxy((widget) => {
    widget.callApi(
      "setCustomParam", { custom: custom }
    );
  }, msob_index);
}
