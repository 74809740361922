
import { documentReady } from './lib/functions/browser/documentReady';
import { getWidgetOrderFormList } from './lib/functions/widget/get-order-form-list';
import { OrderFormHTMLElement } from './lib/functions/widget/order-form-html-element';
import * as widget from './widget';

for(const publicFunctionName in widget) {
    (window as any)[publicFunctionName] = (widget as any)[publicFunctionName];
}

documentReady(() => {

    const legacy_pay_iframes = document.querySelectorAll(".ds24_payIFrame,#ds24_payIFrame");
    legacy_pay_iframes.forEach(item => {
        const parent = item.parentNode as HTMLElement;
        const orderFormElement = OrderFormHTMLElement.createFromLegacyIframe(item as HTMLIFrameElement);
        parent.replaceChild(orderFormElement, item);  
    });

    getWidgetOrderFormList();
});