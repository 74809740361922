import { ds24_promopixel_read_parameter } from "../promopixel/ds24_promopixel";

export type TrackingParameters = {
    affiliate: string;
    campaignkey: string;
    trackingparam: string;
    custom: string;
    ds24_cart_url: string;
};

let store_cachedTrackingParameter: TrackingParameters = {
    affiliate: '',
    campaignkey: '',
    trackingparam: '',
    custom: '',
    ds24_cart_url: ''
};

export function loadWidgetTrackingParameter(): TrackingParameters {
    const promoTracking = ds24_promopixel_read_parameter();

    let affiliate = promoTracking.aff;
    let campaignkey = promoTracking.cam;
    let trackingparam = promoTracking.ds24tr;
    let custom = "";
    let ds24_cart_url = "";

    if (typeof localStorage != "undefined") {
        custom = localStorage.getItem("custom") ?? '';
    
        if (!affiliate || affiliate == "null") {
            affiliate = "";
            campaignkey = "";
        }
    
        if (!custom || custom == "null") {
            custom = "";
        }
    }

    let tokens = location.href.split("?");
    let querystring = tokens[1];
    let have_querystring = typeof querystring != "undefined" && querystring != "";

    if (have_querystring) {
        querystring = querystring.replace(/#/, "&");
    } else {
        tokens = location.href.split("#");
        querystring = tokens[1];
        have_querystring = typeof querystring != "undefined" && querystring != "";
    }

    let have_url_args = false;

    if (have_querystring) {
        const args = querystring.split("&");
    
        for (let i = 0; i < args.length; i++) {
            const arg = args[i];
    
            tokens = arg.split("=");
    
            const key = tokens[0];
            const value = tokens[1];
    
            if (key == "custom") {
                custom = value;
                have_url_args = true;
            }
    
            if (key == "ds24_cart_url") {
                ds24_cart_url = decodeURIComponent(value);
            }
        }
    }

    if (!affiliate && campaignkey && !trackingparam) {
        trackingparam = campaignkey;
        affiliate = "";
        campaignkey = "";
    }

    if (typeof localStorage != "undefined") {
        if (affiliate) {
            localStorage.setItem("ds24aff", affiliate);
            localStorage.setItem("ds24cam", campaignkey);
        }
    
        if (trackingparam) {
            localStorage.setItem("ds24tr", trackingparam);
        }
    
        if (custom) {
            localStorage.setItem("custom", custom);
        }
    }

    /* ignore public 
    DS24_AFFILIATE = affiliate;
    DS24_CAMPAIGNKEY = campaignkey;
    DS24_TRACKINGPARAM = trackingparam;
    DS24_CUSTOM = custom;
    */

    if (have_url_args) {
        let url = location.href;
        url = url
            .replace(/(\?|&|#)aff=*.*?(&|$)/g, "$1")
            .replace(/(\?|&|#)cam=*.*?(&|$)/g, "$1")
            .replace(/(\?|&|#)ds24tr=*.*?(&|$)/g, "$1");
    
        url = url.replace(/(#|\?|&)$/, "");
        history.pushState("", document.title, url);
    }

    return store_cachedTrackingParameter = {
        affiliate,
        campaignkey,
        trackingparam,
        custom,
        ds24_cart_url,
    };
}

export function getWidgetTrackingParameter(): TrackingParameters {
    return store_cachedTrackingParameter;
}

export function resetWidgetTrackingParameter() {
    store_cachedTrackingParameter = {
        affiliate: '',
        campaignkey: '',
        trackingparam: '',
        custom: '',
        ds24_cart_url: ''
    };
}

export function setWidgetTrackingParameter(params: TrackingParameters) {
    store_cachedTrackingParameter = params;
}
