import { OrderFormHTMLElement } from "./lib/functions/widget/order-form-html-element";
import { loadWidgetTrackingParameter } from "./lib/functions/widget/tracking";

loadWidgetTrackingParameter();

export * from "./lib/functions/widget/api";


if (!window.customElements.get('my-order-form')) {
    window.customElements.define('my-order-form', OrderFormHTMLElement);
}

