import { _DS24_EVENT_ORIGIN } from "./get-order-form-list";
import { OrderFormHTMLElement } from "./order-form-html-element";
import { OrderFormElementInterface } from "./order-form-element.interface";
import { getWidgetTrackingParameter } from "./tracking";
import { DEFAULT_PROMOPIXEL_DOMAINS } from "../common/ds24_root_url";

export class OrderFormIframeWrapper implements OrderFormElementInterface {

    public activeStep?: number;
    public totalStepCount?: number;

    private _isReady = false;
    private _element: HTMLElement;

    private _handlePostMessageListener = this._ds24_handlePostMessage.bind(this);
    private _handleIframeLoadListener = () => {

        this._ds24_execSendPayFrameMessage("requestPayFrameReady");

        const trackingParameter = getWidgetTrackingParameter();

        if (trackingParameter.affiliate || trackingParameter.campaignkey || trackingParameter.trackingparam || trackingParameter.custom) {
            this._ds24_sendPayFrameMessage("setTracking", {
                affiliate: trackingParameter.affiliate,
                campaignkey: trackingParameter.campaignkey,
                tracking_key: trackingParameter.trackingparam,
                custom: trackingParameter.custom,
            });
        }
        
        this._ds24_sendPayFrameMessage('setIframeParentURL', {
            iframe_parent_url: window.location.href,
        });


        if (this._index === 0 && this._ds24_elementInViewport()) {
            this._ds24_execSendPayFrameMessage("setFocusOnFirstInput");
        }

        this._ds24_execSendPayFrameMessage("setCancelUrl", {
            cancel_url: location.href,
        });

    };

    constructor(private _iframe: HTMLIFrameElement, private _index: number, element?: OrderFormHTMLElement) {

        if (!element) {
            this._element = this._iframe;
        } else {
            this._element = element;
        }



        window.addEventListener('message', this._handlePostMessageListener);


        this._iframe.addEventListener('load', this._handleIframeLoadListener);
    }

    private _getAllwoedDomainList() {
        return [
            ...DEFAULT_PROMOPIXEL_DOMAINS,
            _DS24_EVENT_ORIGIN,
        ];
    }

    private _ds24_handlePostMessage(e: MessageEvent) {
        
        if (
            typeof e.origin != "undefined" &&
            this._getAllwoedDomainList().indexOf(e.origin) < 0
        ) {
            console.log(
                "DS24: Ignored event from " +
                e.origin +
                ', because it does not match our origin "' +
                _DS24_EVENT_ORIGIN +
                '":',
                e
            );
            return;
        }
    
        let data;
        try {
            data = JSON.parse(e.data);
        } catch (ex) {
            console.log(
                "DS24: Ignored event because data is not processable:",
                e,
                (ex as Error).message
            );
            return;
        }
        
        if (
            typeof data.iframe_index == "undefined" ||
            typeof data.ds24action == "undefined"
        ) {
            return;
        }
    
        const action = data.ds24action;
        const iframe_index = data.iframe_index;
        
        if (('' + iframe_index) !== ('' + this._index)) {
            return;
        }
    
        const is_step_changed =
            typeof this.activeStep != "undefined" &&
            this.activeStep != data.new_step_no;
    
        this.activeStep = data.new_step_no;
        this.totalStepCount = data.total_step_count;

        let iframeUrl: URL;
        let redirectUrl: string;
    
        switch (action) {
        case "setPayFrameHeight":
            this._ds24_setIFrameHeight(
                data.payload.height,
                data.payload.scroll_to
            );

            if (!this._isReady) {
                this._element.dispatchEvent(new CustomEvent('ds24_ready', { composed: true } ));
                this._ds24_maybeSentOnloadEvent();
            }
            this._isReady = true;
            break;
    
        case "cartUpdated":
            this._element.dispatchEvent(new CustomEvent("ds24_cartUpdated", {
                detail: data.payload,
                bubbles: false,
                cancelable: true,
                composed: true,
            }));
            break;
    
        case "stepChange":
            // empty
            break;
    
        case "redirect":
            iframeUrl = new URL(this._iframe.src);
            redirectUrl = iframeUrl.origin + '/extern/ajax/redirect/' + data.payload.redirect_id;
            
            if (data.payload.delay_ms) {
                window.setTimeout(() =>  window.location.href = redirectUrl, data.payload.delay_ms );
            } else {
                window.location.href = redirectUrl;
            }
            break;
    
        case "sendScrollPosition":
            //var iframes = ds24_getPayIframes();
    
            //var iframe = iframes[iframe_index];
    
            data.payload.iframe_offset = this._ds24_getAbsoluteOffset();
            data.payload.window_offset = {
                top: window.pageYOffset,
                left: window.pageXOffset,
            };
            this._ds24_sendPayFrameMessage(
                "receiveScrollPosition",
                data.payload,
            );
    
            break;
        }
    
        if (is_step_changed) {
            const event = new CustomEvent("ds24_stepChange", {
                detail: { newStep: this.activeStep },
                bubbles: false,
                cancelable: true,
                composed: true,
            });
            this._iframe.dispatchEvent(event);
        }
    }
  


    private _ds24_execSendPayFrameMessage(action: string, payload?: any, tries = 0) {

        const data = { ds24action: action, payload: payload, iframe_index: this._index, parent_url: location.href };
        if (typeof(this._iframe.contentWindow?.postMessage) !== 'undefined') {
            (this._iframe.contentWindow as WindowProxy).postMessage(JSON.stringify(data), '*' );
        } else {
            if (tries > 5) {
                return;
            }
    
            window.setTimeout(() => {
                this._ds24_execSendPayFrameMessage(action, payload, tries + 1);
            }, 500);
            console.log(
                "DS24: repeated message for iframe #" + this._index + ": ",
                action,
                payload
            );
        }
    }

    private _ds24_sendPayFrameMessage(action: string, payload: any) {
        if (!this._isReady) {
            const timeoutReady = setTimeout(() => {
                this._element.removeEventListener('ds24_ready', readyListener);
                console.log(
                    "DS24: Timeout reached! Order form is not ready after 25sec. (" + action + ")",
                );
            }, 25000);
            const readyListener = () => {
                clearTimeout(timeoutReady);
                this._ds24_execSendPayFrameMessage(action, payload);
            };
            this._element.addEventListener('ds24_ready', readyListener);
        } else {
            this._ds24_execSendPayFrameMessage(action, payload);
        } 
    }

    private _ds24_setIFrameHeight(height: number, scroll_to?: number) {
        const new_height = "" + (height + 4) + "px";
      
        this._iframe.style.visibility = "hidden";
      
        // some IE versions need a bit added or scrollbar appears
        this._iframe.style.height = new_height;
      
        this._iframe.style.visibility = "visible";
      
        if (typeof(scroll_to) !== 'undefined' && !isNaN(scroll_to as any)) {
          const pos = this._ds24_getAbsoluteOffset();
      
          let newPageTop = pos.top + scroll_to;
          if (newPageTop < 0) {
            newPageTop = 0;
          }

          if (newPageTop - window.pageYOffset > 0) {
            return;
          }
      
          window.scrollTo(window.pageXOffset, newPageTop);
        }
    }

    private _ds24_getAbsoluteOffset() {
        let top = 0;
        let left = 0;
        let element: HTMLElement | null = this._iframe as HTMLElement;
        do {
          top += element?.offsetTop || 0;
          left += element?.offsetLeft || 0;
          element = element?.offsetParent as HTMLElement;
        } while(element);
      
        return {
          top: top,
          left: left,
        };
    }


    private _ds24_maybeSentOnloadEvent() {
        const event = new CustomEvent("ds24-msob-onload", {
            detail: { iframe_index: this._index, iframe_object: this._iframe },
        });

        window.dispatchEvent(event);
    }


    private _ds24_elementInViewport() {
        let el: HTMLElement = this._iframe as HTMLIFrameElement;
        const width = el.offsetWidth;
        const height = el.offsetHeight;
        let top = el.offsetTop;
        let left = el.offsetLeft;
        
    
        while (el.offsetParent) {
            el = el.offsetParent as HTMLElement;
            top += el.offsetTop;
            left += el.offsetLeft;
        }
    
        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            top + height <= window.pageYOffset + window.innerHeight &&
            left + width <= window.pageXOffset + window.innerWidth
        );
    }


    public callApi(action: string, payload: any) {
        this._ds24_sendPayFrameMessage(action, payload);
    }

    public addEventListener(event: string, listener: (e: Event) => void, capture?: boolean | undefined): void {
        this._element.addEventListener(event, listener, capture);
    }

    public destroy() {
        window.removeEventListener('message', this._handlePostMessageListener);
        this._iframe.removeEventListener('load', this._handleIframeLoadListener);
    }
}